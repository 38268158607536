import Layout from "../../layouts"
import React, {useState, useContext, useEffect} from "react"
import {GlobalStateContext} from "../../context/GlobalContextProvider"
import {Box,  Card} from "@mui/material"

import Hider from "../../components/utils/Hider"
import CfpModify from "../../components/cfp/cfpmodify"
import {CfpDgBindMember} from "../../components/cfp/cfpdialogbindmember"
import {CfpBackTitle} from "../../components/cfp/cfpdetailstop"
import {CfpCmDetails} from "../../components/cfp/cfpdetails"
import {gUtils} from "../../components/utils/gutils"
import CheckTable from "../../components/attendance/checkTable";

import {navigate} from "gatsby";
import {gConfig} from "../../chatai/utils/config";
import {wordConfig} from "../../config/configFont";

export default function CDetails({location = {}}) {
    const {state = {}} = location
    const {data} = state || {};

    const {
        gCfpCards, gCfp, gIotCfp,
        setCustomModalOpen,

        gCompanyEmployee,
        setModalContent
    } = useContext(GlobalStateContext)

    const [edit, setEdit] = useState(false)
    const [mdetails, setMdetails] = useState({})
    const [uuids, setUuids] = useState([])


    const [isBindMember, setIsBindMember] = useState(false)
    useEffect(()=>{

        let  findItem=gCfpCards.allCards.find(item=>item.cardID===data.cardID)

        if (findItem){
            setUuids(findItem.uuids)
        }
    },[gCfpCards.allCards])
    useEffect(() => {
        gIotCfp.subIotDevices()
        return ()=>{
            gIotCfp.cancelSubscriptions();

        }
    }, []);
    useEffect(() => {
        if (!data || Object.keys(data).length === 0) {
            navigate("/");
        }else {
            setMdetails({
                ID: data.cardID,
                [wordConfig.touchDeviceName]: gCompanyEmployee.memberIDToName(data.memberID) ?? "-",
                [wordConfig.touchCertEquipment]: gUtils.uuidsToNames(uuids, gCfp.cfpDevices),
            })


        }

    }, [ uuids,data, navigate,state])



    const handleEditClick = () => {
        setEdit(true)
    }

    const handleSureClick = (v) => {
        setEdit(false)
        data.name=v
        gCfpCards.updateCards(data)
    }

    const handleClose = () => {
        setIsBindMember(false)
    }

    const handleSure = (member) => {
        setIsBindMember(false)
        data.memberID=member.subUUID

        gCfpCards.updateCards(data)

    }

    const handleBindMmClick = () => {
        setIsBindMember(true)
    }
    const handleOpenModal = () => {

        let  find=gCfpCards.allCards.find(item=>item.cardID===data.cardID)


      let value=  gCfp.cfpDevices.filter(item => !find.uuids.some(it => it === item.deviceUUID))
        setModalContent(
            <CheckTable
                title={"認証機器を選択"}
                setOpenModal={setCustomModalOpen}
                selectableRows={"multiple"}
                handleClose={() => {
                    setCustomModalOpen(false)
                }}
                data={value}
                handleCheck={handleCheck}
                location={location}
                isWifi={true}
            />
        )
        setCustomModalOpen(true)
    }
    const handleCheck = async (items) => {

        setCustomModalOpen(false)
        for (const item of items) {
            await gIotCfp.sendCmd(item.deviceUUID,gConfig.cmdCode.cardPut,gUtils.toCardInfo(data.cardID, data.cardType||2, data.name))
            await new Promise(resolve => setTimeout(resolve, 2000))
          }



    }

    return (
        <Layout location={location}>
            <Card>
                <Hider show={edit}>
                    <CfpModify
                        type="c"
                        state={data}
                        leftClick={() => setEdit(false)}
                        sureClick={handleSureClick}
                    />
                    <Box>
                        <CfpBackTitle title={data?.name||''} clickEdit={handleEditClick}/>
                        <CfpCmDetails mdetails={mdetails} bindMm={handleBindMmClick} bindTouch={handleOpenModal}

                                      delBot={(i)=>{

                                         if (gCfp.getWifiState(uuids[i])) {
                                             gIotCfp.sendCmd(uuids[i],gConfig.cmdCode.cardDelete,gUtils.toCardInfo(data.cardID, data.cardType, data.name))

                                          }

                                      }}
                        />
                    </Box>
                </Hider>
            </Card>
            <CfpDgBindMember
                mOpen={isBindMember}
                handleClose={handleClose}
                handleSure={handleSure}
            />
        </Layout>
    )
}
